export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {}
}

const setUser = user =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

export const handleLogin = ({ username, password, onSuccess, onFailure }) => {
  if (
    username === process.env.GATSBY_USERNAME_ROB &&
    password === process.env.GATSBY_PASSWORD_ROB
  ) {
    setUser({
      username: process.env.GATSBY_USERNAME_ROB,
      name: 'Robert',
      token: process.env.GATSBY_LOGGED_IN_ROB_TOKEN,
    })
    return onSuccess()
  }

  if (
    username === process.env.GATSBY_USERNAME_DINA &&
    password === process.env.GATSBY_PASSWORD_DINA
  ) {
    setUser({
      username: process.env.GATSBY_USERNAME_DINA,
      name: 'Dina',
      token: process.env.GATSBY_LOGGED_IN_DINA_TOKEN,
    })
    return onSuccess()
  }

  return onFailure()
}

export const isLoggedIn = () => {
  const { token } = getUser()
  return !!(
    token === process.env.GATSBY_LOGGED_IN_ROB_TOKEN ||
    token === process.env.GATSBY_LOGGED_IN_DINA_TOKEN
  )
}

export const logout = ({ onSuccess }) => {
  setUser({})
  onSuccess()
}
