import { useEffect } from 'react'
import { navigate } from 'gatsby'

import { isLoggedIn } from '../services/auth'

export default () => {
  useEffect(() => {
    navigate(isLoggedIn() ? '/app/home' : '/login')
  }, [])
  return null
}
